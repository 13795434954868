import React, { FC, useCallback, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import NavigationLinks from '../NavigationLinks'
import { useScreenSize } from '../../hooks/useScreenSize'

import './NavigationBar.scss'

enum NavbarBackground {
  solid = 'solid',
  transparent = 'transparent',
}

const NavigationBar: FC = () => {
  const [navbarBackground, setNavbarBackground] = useState<NavbarBackground>(
    NavbarBackground.transparent,
  )
  // const [isSmallScreen, setIsSmallScreen] = useState(false)
  const { width } = useScreenSize()
  const [showMenu, setShowMenu] = useState(false)

  const handleScroll = useCallback(() => {
    if (window.scrollY > 69) {
      setNavbarBackground(NavbarBackground.solid)
    } else {
      setNavbarBackground(NavbarBackground.transparent)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    if (width > 768 && showMenu) {
      setShowMenu(false)
    }
  }, [width, showMenu])

  const openMenu = () => {
    setShowMenu(true)
  }

  const closeMenu = () => {
    setShowMenu(false)
  }

  return (
    <header
      data-testid="header"
      className={
        navbarBackground === NavbarBackground.solid
          ? 'navigation-bar solid'
          : 'navigation-bar'
      }
    >
      <div className="navigation-content">
        <div
          className={
            navbarBackground === NavbarBackground.solid ? 'logo show' : 'logo'
          }
        >
          <Link to="/">
            <StaticImage
              src="../../assets/images/NeonCat-logo.png"
              alt="NonCat logo"
              placeholder="tracedSVG"
              layout="constrained"
              height={50}
            />
          </Link>
        </div>
        <nav className={showMenu ? 'links show' : 'links'}>
          <NavigationLinks onClick={closeMenu} />
          <div className="close" onClick={closeMenu}>
            <StaticImage
              src="../../assets/images/close.svg"
              alt="Menu hamburger"
            />
          </div>
        </nav>
        <div className="hamburger" onClick={openMenu}>
          <StaticImage
            src="../../assets/images/menu-hamburger.svg"
            alt="Menu hamburger"
            width={50}
          />
        </div>
      </div>
    </header>
  )
}

export default NavigationBar
