import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

import NavigationBar from '../NavigationBar'
import Footer from '../Footer'

import Favicon from '../../assets/images/favicon.ico'
import NeonCatSocialImage from '../../assets/images/neoncat-social.png'

interface Props {
  title: string
}

const Layout: FC<Props> = ({ children, title }) => {
  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <meta
          name="description"
          content="Official website of NeonCat software development company from Novi Sad, Serbia."
        />
        <meta
          name="keywords"
          content="NeonCat, Software, Development, Engineering, Solutions, Computer Science"
        />
        <link rel="icon" href={Favicon} type="image/x-icon" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`NeonCat - ${title}`} />
        <meta property="og:image" content={NeonCatSocialImage} />
        <meta
          property="og:description"
          content="Official website of NeonCat software development company from Novi Sad, Serbia. We provide custom web and mobile solutions - front to back."
        />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width, height=device-height, viewport-fit=cover"
        />
        <title>{`NeonCat - ${title}`}</title>
      </Helmet>
      <NavigationBar />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
