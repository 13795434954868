import React, { FC } from 'react'
import {
  FaFacebook,
  FaLinkedin,
  FaInstagramSquare,
  FaGithub,
} from 'react-icons/fa'
import NavigationLinks from '../NavigationLinks'
import './Footer.scss'

const Footer: FC = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer>
      <div className="social-links">
        <a
          href="https://www.facebook.com/neoncat.io"
          target={'_blank'}
          title="Facebook"
          aria-label="Facebook"
          rel="noreferrer"
        >
          <FaFacebook />
        </a>
        <a
          href="https://www.linkedin.com/company/neoncat"
          target={'_blank'}
          title="LinkedIn"
          aria-label="LinkedIn"
          rel="noreferrer"
        >
          <FaLinkedin />
        </a>
        <a
          href="https://www.instagram.com/neoncat.io"
          target={'_blank'}
          title="Instagram"
          aria-label="Instagram"
          rel="noreferrer"
        >
          <FaInstagramSquare />
        </a>
        <a
          href="https://github.com/neoncat-io"
          target={'_blank'}
          title="GitHub"
          aria-label="GitHub"
          rel="noreferrer"
        >
          <FaGithub />
        </a>
      </div>
      <nav>
        <NavigationLinks />
      </nav>
      <div className="copyright">Copyright &copy; {currentYear} Neon Cat</div>
    </footer>
  )
}

export default Footer
