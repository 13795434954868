import React, { FC } from 'react'
import { Link } from 'gatsby'

interface Props {
  onClick?: () => void
}

const NavigationBar: FC<Props> = ({ onClick }) => {
  return (
    <>
      <Link
        to="/"
        className="link"
        activeClassName="active"
        onClick={onClick}
        aria-label="Home"
      >
        Home
      </Link>
      <Link
        to="/#about-us"
        className="link"
        activeClassName="active"
        onClick={onClick}
        aria-label="About Us"
      >
        About Us
      </Link>
      <Link
        to="/#our-work"
        className="link"
        activeClassName="active"
        onClick={onClick}
        aria-label="Our Work"
      >
        Our Work
      </Link>
      <Link
        to="/#full-service"
        className="link"
        activeClassName="active"
        onClick={onClick}
        aria-label="Full Service"
      >
        Full Service
      </Link>
      <Link
        to="/#projects"
        className="link"
        activeClassName="active"
        onClick={onClick}
        aria-label="Projects"
      >
        Projects
      </Link>
      <Link
        to="/#contact"
        className="link"
        activeClassName="active"
        onClick={onClick}
        aria-label="Contact"
      >
        Contact
      </Link>
      <Link
        to="/#technologies"
        className="link"
        activeClassName="active"
        onClick={onClick}
        aria-label="Technologies"
      >
        Technologies
      </Link>
    </>
  )
}

export default NavigationBar
